<template>
  <b-modal
    size="md"
    id="modal-agentur-duplicates-merge"
    scrollable
    centered
    :title="$t('AGENTUREN.AGENTURENZUSAMMEFUEHREN')"
    @hide="onHide($event)"
  >
    Sollen die Agenturen zusammengeführt werden?
    <div class="mt-3 row">
      <b-input-group
        aria-describedby="input-live-feedback-1"
        class="col-md-12 mb-3"
        size="lg"
        prepend="Agentur 1 Nummer"
      >
        <b-form-input v-model="agentur1Id" :state="agentur1IdState" type="number"></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback-1">
          {{ $t('AGENTUREN.BITTEGUELTIGEAGENTURNUMMEREINGEBEN') }}
        </b-form-invalid-feedback>
      </b-input-group>

      <b-input-group
        aria-describedby="input-live-feedback-2"
        class="col-md-12"
        size="lg"
        prepend="Agentur 2 Nummer"
      >
        <b-form-input v-model="agentur2Id" :state="agentur2IdState" type="number"></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback-2">
          {{ $t('AGENTUREN.BITTEGUELTIGEAGENTURNUMMEREINGEBEN') }}
        </b-form-invalid-feedback>
      </b-input-group>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <b-button variant="success" @click="onModalOkay()"> {{ $t('DUBLETTEN.MERGE') }} </b-button>
      <b-button variant="danger" @click="cancel()"> {{ $t('COMMON.ABBRECHEN') }} </b-button>
    </template>
  </b-modal>
</template>

<script>
import { CREATE_AGENTUR_DUPLICATE_AND_ACCEPT } from '@/core/agenturen/stores/agenturDuplicates.module';
export default {
  data() {
    return {
      agentur1Id: null,
      agentur1IdState: null,
      agentur2Id: null,
      agentur2IdState: null,
    };
  },
  methods: {
    onModalOkay() {
      const agentur1Id = Number(this.agentur1Id);
      const agentur2Id = Number(this.agentur2Id);
      this.agentur1IdState = typeof agentur1Id === 'number' && agentur1Id > 0;
      this.agentur2IdState = typeof agentur2Id === 'number' && agentur2Id > 0;
      if (this.agentur1IdState && this.agentur2IdState) {
        this.$store
          .dispatch(CREATE_AGENTUR_DUPLICATE_AND_ACCEPT, {
            agentur1Id: agentur1Id,
            agentur2Id: agentur2Id,
          })
          .then(() => {
            this.$bvModal.hide('modal-agentur-duplicates-merge');
            this.toast(this.$t('WORKFLOWS.COMMON.SAVED'));
          });
      }
    },
    onHide($event) {
      if ($event.trigger !== 'ok' && $event.trigger !== 'event') {
        this.toast(this.$t('COMMON.AKTIONABGEBROCHEN'), 'secondary');
      }
    },
  },
};
</script>
