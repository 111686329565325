<template>
  <div class="d-flex flex-column flex-grow-1">
    <FilterId
      class="mt-4"
      @input="setAgenturDuplicatesIdFilter"
      :optionsArray="agenturDuplicatesIdFilterOptions"
      :value="agenturDuplicatesIdFilter"
      :storeFunction="getAgenturFilterFunction"
      :placeholder="$t('AGENTUREN.AGENTURNUMMER')"
      objectName="Agentur"
    ></FilterId>
    <FilterDublettenState
      class="mt-4"
      :value="agenturDuplicatesStateFilter"
      @input="setAgenturDuplicatesStateFilter"
    ></FilterDublettenState>
    <div class="w-100 mt-4 flex-grow-1 flex-column">
      <b-button
        :disabled="isCurrentFilterApplied"
        lg
        block
        @click="onClickSaveFilter($event)"
        variant="primary"
      >
        {{ $t('COMMON.FILTERANWENDEN') }}
      </b-button>
      <b-button
        block
        variant="danger"
        @click="onClickResetFilter($event)"
        v-show="isAnyFilterApplied"
        v-t="'REISEN.COMMON.RESET'"
      ></b-button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import {
  GET_AGENTUR_DUPLICATES,
  RESET_FILTER,
  SET_AGENTUR_DUPLICATES_ID_FILTER,
  GET_AGENTUR_DUPLICATES_ID_FILTER_OPTIONS,
  SET_AGENTUR_DUPLICATES_STATE_FILTER,
  SET_AGENTUR_DUPLICATES_IS_CURRENT_FILTER_APPLIED,
  SET_AGENTUR_DUPLICATES_SKIP,
} from '@/core/agenturen/stores/agenturDuplicates.module';
import FilterId from '@/components/common/filter-id.vue';
import FilterDublettenState from '@/components/common/filter-dubletten-state.vue';

export default {
  components: {
    FilterId,
    FilterDublettenState,
  },
  computed: {
    ...mapState({
      isAnyFilterApplied: state => state.agenturDuplicates.isAnyFilterApplied,
      isCurrentFilterApplied: state => state.agenturDuplicates.isCurrentFilterApplied,
      appliedAgenturDuplicatesFilter: state => state.agenturDuplicates.appliedAgenturDuplicatesFilter,
      agenturDuplicatesIdFilterOptions: state => state.agenturDuplicates.agenturIdFilterOptions,
      agenturDuplicatesIdFilter: state => state.agenturDuplicates.filter.idFilter,
      agenturDuplicatesStateFilter: state => state.agenturDuplicates.filter.duplicateState,
    }),
  },
  methods: {
    getAgenturFilterFunction(id) {
      return this.$store.dispatch(GET_AGENTUR_DUPLICATES_ID_FILTER_OPTIONS, { idFilter: id });
    },
    onClickSaveFilter() {
      this.$store.commit(SET_AGENTUR_DUPLICATES_SKIP, 0);
      this.$store.dispatch(GET_AGENTUR_DUPLICATES);
    },
    onClickResetFilter() {
      this.$store.dispatch(RESET_FILTER);
    },
    setAgenturDuplicatesIdFilter(id) {
      this.$store.commit(SET_AGENTUR_DUPLICATES_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(SET_AGENTUR_DUPLICATES_ID_FILTER, id);
    },
    setAgenturDuplicatesStateFilter(state) {
      this.$store.commit(SET_AGENTUR_DUPLICATES_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(SET_AGENTUR_DUPLICATES_STATE_FILTER, state);
    },
  },
};
</script>
