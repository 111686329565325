<template>
  <div>
    <FilterOverview title="Dublettenprüfung" :isBusy="isBusy" @reload="onReload">
      <template #toolbar-left>
        <AgenturDublettenConfirmationToolbarBase
          :isParentLoading="isBusy"
        ></AgenturDublettenConfirmationToolbarBase>
      </template>
      <template #toolbar-right>
        <b-button variant="primary" size="sm" @click="$bvModal.show('modal-agentur-duplicates-merge')"
          >Manuell zusammenführen</b-button
        >
        <AgenturDuplicatesMergeModal></AgenturDuplicatesMergeModal>
      </template>
      <template #filter> <AgenturDuplicatesFilter /></template>
      <template #table>
        <AgenturDuplicatesOverview :stickyHeaderSize="tableHeight" />
      </template>
    </FilterOverview>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { GET_AGENTUR_DUPLICATES } from '@/core/agenturen/stores/agenturDuplicates.module';
import AgenturDuplicatesOverview from '@/components/agenturen/agentur-duplicates-overview.vue';
import AgenturDuplicatesFilter from '@/components/agenturen/agentur-duplicates-filter.vue';
import AgenturDublettenConfirmationToolbarBase from '@/components/agenturen/agentur-duplicates-confirmation-toolbar-base.vue';
import AgenturDuplicatesMergeModal from '@/components/agenturen/agentur-duplicates-merge-modal.vue';
import FilterOverview from '@/components/common/filter-overview.vue';

export default {
  name: 'KundeOverviewComponent',
  components: {
    AgenturDuplicatesOverview,
    AgenturDuplicatesFilter,
    AgenturDublettenConfirmationToolbarBase,
    AgenturDuplicatesMergeModal,
    FilterOverview,
  },
  data() {
    return {
      tableHeight: 'auto',
    };
  },
  computed: {
    ...mapState({
      isBusy: state => state.agenturDuplicates.isBusy,
    }),
  },
  methods: {
    onReload() {
      this.$store.dispatch(GET_AGENTUR_DUPLICATES);
    },
  },
};
</script>
