var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex flex-column flex-lg-row"},[(_vm.agenturDuplicates)?_c('div',{staticClass:"flex-lg-row-fluid"},[_vm._l((_vm.agenturDuplicates),function(agenturDuplicate){return _c('div',{key:agenturDuplicate.id,staticClass:"card card-custom w-100 gutter-b confirmation-card",class:{
          'overlay overlay-block overlay-layer bg-dark-o-10': _vm.isBusy,
          hidden: agenturDuplicate.hidden,
        }},[_c('div',{staticClass:"card-header ribbon ribbon-top"},[_c('div',{staticClass:"ribbon-target mb-2",class:agenturDuplicate.state.toLowerCase()},[_vm._v(" "+_vm._s(_vm.$t('DUBLETTEN.CONFIRMATION.' + agenturDuplicate.state.toUpperCase()))+" ")]),_vm._m(0,true),_c('div',{staticClass:"card-toolbar text-muted"},[(agenturDuplicate.resultCodes && agenturDuplicate.resultCodes.length > 0)?[_c('b-icon-journal-code',{staticClass:"ml-5 mr-1 mb-0 h5",attrs:{"title":"Result Codes"}}),_vm._l((agenturDuplicate.resultCodes),function(code,index){return _c('MelissaCode',{key:index,attrs:{"isSeperated":index !== agenturDuplicate.resultCodes.length - 1,"code":code}})})]:_vm._e(),(agenturDuplicate.errorCodes && agenturDuplicate.errorCodes.length > 0)?[_c('b-icon-journal-x',{staticClass:"ml-5 mr-1 mb-0 h5",attrs:{"title":"Error Codes"}}),_vm._l((agenturDuplicate.errorCodes),function(code,index){return _c('MelissaCode',{key:index,attrs:{"isSeperated":index !== agenturDuplicate.errorCodes.length - 1,"code":code}})})]:_vm._e(),_c('b-icon-arrow-repeat',{staticClass:"ml-5 mr-1 mb-0 h5",attrs:{"title":"Updated"}}),_vm._v(" "+_vm._s(_vm._f("dateTime")(agenturDuplicate.updatedDateUtc))+" "),_c('b-icon-file-earmark',{staticClass:"ml-5 mr-1 mb-0 h5",attrs:{"title":"Erstellt"}}),_vm._v(" "+_vm._s(_vm._f("dateTime")(agenturDuplicate.createdDateUtc))+" "),_c('b-icon-person-check-fill',{staticClass:"ml-5 mr-1 mb-0 h5",attrs:{"title":"Bearbeiter"}}),_vm._v(" "+_vm._s(_vm.$store.getters.getUsernameById(agenturDuplicate.bearbeiter) || '-')+" ")],2)]),_c('div',{staticClass:"card-body"},[_c('AgenturDuplicatesCompareTable',{attrs:{"agentur1":agenturDuplicate.agentur1,"agentur2":agenturDuplicate.agentur2}})],1),(
            agenturDuplicate.state === 'New' ||
            agenturDuplicate.state === 'Declined' ||
            agenturDuplicate.state === 'Merged'
          )?_c('div',{staticClass:"card-footer d-flex justify-content-between"},[(agenturDuplicate.state === 'New' || agenturDuplicate.state === 'Declined')?_c('b-button',{staticClass:"btn btn-primary mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.onSendValidationClick(agenturDuplicate, 0)}}},[_c('b-icon-check2-circle',{staticClass:"mr-1"}),_vm._v(_vm._s(agenturDuplicate.state === 'New' ? 'Annehmen' : 'Korrektur: Annehmen'))],1):_vm._e(),(agenturDuplicate.state === 'New')?_c('b-button',{staticClass:"btn btn-primary",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.onSendValidationClick(agenturDuplicate, 1)}}},[_c('b-icon-x-circle',{staticClass:"mr-1"}),_vm._v("Ablehnen")],1):_vm._e(),(agenturDuplicate.state === 'Merged')?_c('b-button',{staticClass:"btn btn-primary",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.onSendValidationClick(agenturDuplicate, 2)}}},[_c('b-icon-x-circle',{staticClass:"mr-1"}),_vm._v("Merge rückgängig machen")],1):_vm._e()],1):_vm._e()])}),(_vm.count > _vm.top || _vm.count === 0)?_c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"card-body"},[(_vm.count === 0)?_c('LoadSpinnerWidget',{attrs:{"dataAvailable":_vm.count !== 0,"isLoading":false,"showText":true}}):_vm._e(),(_vm.count > _vm.top)?_c('SKRPagination',{attrs:{"overwriteCurrentPage":_vm.currentPage,"totalRows":_vm.count,"isLoading":_vm.isBusy,"perPageOverwrite":_vm.top,"currentRows":_vm.agenturDuplicates.length},on:{"pageChange":_vm.onPageChange}}):_vm._e()],1)]):_vm._e()],2):_c('div',{staticClass:"w-100"},_vm._l((_vm.top),function(n){return _c('b-skeleton',{key:n,staticClass:"gutter-b",attrs:{"width":"100%","height":"377px"}})}),1)]),_c('AgenturDuplicatesConfirmationModal')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"})])
}]

export { render, staticRenderFns }