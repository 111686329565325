<template>
  <div>
    <div class="d-flex flex-column flex-lg-row">
      <div v-if="agenturDuplicates" class="flex-lg-row-fluid">
        <div
          v-for="agenturDuplicate in agenturDuplicates"
          :key="agenturDuplicate.id"
          class="card card-custom w-100 gutter-b confirmation-card"
          :class="{
            'overlay overlay-block overlay-layer bg-dark-o-10': isBusy,
            hidden: agenturDuplicate.hidden,
          }"
        >
          <div class="card-header ribbon ribbon-top">
            <div class="ribbon-target mb-2" :class="agenturDuplicate.state.toLowerCase()">
              {{ $t('DUBLETTEN.CONFIRMATION.' + agenturDuplicate.state.toUpperCase()) }}
            </div>
            <div class="card-title">
              <h3 class="card-label"></h3>
            </div>
            <div class="card-toolbar text-muted">
              <template v-if="agenturDuplicate.resultCodes && agenturDuplicate.resultCodes.length > 0">
                <b-icon-journal-code title="Result Codes" class="ml-5 mr-1 mb-0 h5"></b-icon-journal-code>
                <MelissaCode
                  v-for="(code, index) in agenturDuplicate.resultCodes"
                  :key="index"
                  :isSeperated="index !== agenturDuplicate.resultCodes.length - 1"
                  :code="code"
                />
              </template>
              <template v-if="agenturDuplicate.errorCodes && agenturDuplicate.errorCodes.length > 0">
                <b-icon-journal-x title="Error Codes" class="ml-5 mr-1 mb-0 h5"></b-icon-journal-x>
                <MelissaCode
                  v-for="(code, index) in agenturDuplicate.errorCodes"
                  :isSeperated="index !== agenturDuplicate.errorCodes.length - 1"
                  :key="index"
                  :code="code"
                />
              </template>
              <b-icon-arrow-repeat title="Updated" class="ml-5 mr-1 mb-0 h5"></b-icon-arrow-repeat>
              {{ agenturDuplicate.updatedDateUtc | dateTime }}
              <b-icon-file-earmark title="Erstellt" class="ml-5 mr-1 mb-0 h5"></b-icon-file-earmark>
              {{ agenturDuplicate.createdDateUtc | dateTime }}
              <b-icon-person-check-fill
                title="Bearbeiter"
                class="ml-5 mr-1 mb-0 h5"
              ></b-icon-person-check-fill>
              {{ $store.getters.getUsernameById(agenturDuplicate.bearbeiter) || '-' }}
            </div>
          </div>
          <div class="card-body">
            <AgenturDuplicatesCompareTable
              :agentur1="agenturDuplicate.agentur1"
              :agentur2="agenturDuplicate.agentur2"
            />
          </div>
          <div
            v-if="
              agenturDuplicate.state === 'New' ||
              agenturDuplicate.state === 'Declined' ||
              agenturDuplicate.state === 'Merged'
            "
            class="card-footer d-flex justify-content-between"
          >
            <b-button
              v-if="agenturDuplicate.state === 'New' || agenturDuplicate.state === 'Declined'"
              @click="onSendValidationClick(agenturDuplicate, 0)"
              variant="primary"
              class="btn btn-primary mr-1"
            >
              <b-icon-check2-circle class="mr-1"></b-icon-check2-circle
              >{{ agenturDuplicate.state === 'New' ? 'Annehmen' : 'Korrektur: Annehmen' }}</b-button
            >
            <b-button
              v-if="agenturDuplicate.state === 'New'"
              @click="onSendValidationClick(agenturDuplicate, 1)"
              variant="secondary"
              class="btn btn-primary"
            >
              <b-icon-x-circle class="mr-1"></b-icon-x-circle>Ablehnen</b-button
            >
            <b-button
              v-if="agenturDuplicate.state === 'Merged'"
              @click="onSendValidationClick(agenturDuplicate, 2)"
              variant="warning"
              class="btn btn-primary"
            >
              <b-icon-x-circle class="mr-1"></b-icon-x-circle>Merge rückgängig machen</b-button
            >
          </div>
        </div>

        <div v-if="count > top || count === 0" class="card card-custom">
          <div class="card-body">
            <LoadSpinnerWidget
              v-if="count === 0"
              :dataAvailable="count !== 0"
              :isLoading="false"
              :showText="true"
            ></LoadSpinnerWidget>
            <SKRPagination
              v-if="count > top"
              :overwriteCurrentPage="currentPage"
              :totalRows="count"
              :isLoading="isBusy"
              :perPageOverwrite="top"
              :currentRows="agenturDuplicates.length"
              @pageChange="onPageChange"
            ></SKRPagination>
          </div>
        </div>
      </div>
      <div v-else class="w-100">
        <b-skeleton v-for="n in top" :key="n" class="gutter-b" width="100%" height="377px"></b-skeleton>
      </div>
    </div>
    <AgenturDuplicatesConfirmationModal />
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/components/_variables.bootstrap.scss';

.ribbon-target {
  &.new {
    background-color: $primary;
  }
  &.merged {
    background-color: $success;
  }
  &.declined {
    background-color: $danger;
  }
}

.confirmation-card {
  transition: 0.3s ease-out;
  opacity: 1;
  &.hidden {
    opacity: 0;
    left: 10px;
  }
}
</style>

<script>
import {
  GET_AGENTUR_DUPLICATES,
  AGENTUR_DUPLICATES_SORT_CHANGED,
  AGENTUR_DUPLICATES_PAGE_CHANGED,
  SET_CONFIRMATION_MODAL_PAIR_AND_TRIGGER,
  SAVE_AGENTUR_DUPLICATES_MERGE_STATE,
} from '@/core/agenturen/stores/agenturDuplicates.module';

import { mapState } from 'vuex';

import LoadSpinnerWidget from '@/components/common/load-spinner-widget.vue';
import SKRPagination from '@/components/common/skr-pagination.vue';
import AgenturDuplicatesConfirmationModal from '@/components/agenturen/agentur-duplicates-confirmation-modal.vue';

import AgenturDuplicatesCompareTable from '@/components/agenturen/agentur-duplicates-compare-table.vue';
import MelissaCode from '@/components/common/melissa-code.vue';

export default {
  components: {
    LoadSpinnerWidget,
    SKRPagination,
    AgenturDuplicatesCompareTable,
    AgenturDuplicatesConfirmationModal,
    MelissaCode,
  },
  props: {
    stickyHeaderSize: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    ...mapState({
      isBusy: state => state.agenturDuplicates.isBusy,
      agenturDuplicates: state => state.agenturDuplicates.agenturDuplicates,
      orderBy: state => state.agenturDuplicates.orderBy,
      count: state => state.agenturDuplicates.count,
      top: state => state.agenturDuplicates.top,
    }),
  },
  mounted() {
    this.$store.dispatch(GET_AGENTUR_DUPLICATES);
  },
  methods: {
    onSendValidationClick(agenturDuplicate, trigger) {
      if (agenturDuplicate.agentur1.nameMatch) {
        this.$store.dispatch(SAVE_AGENTUR_DUPLICATES_MERGE_STATE, {
          agentur1Id: agenturDuplicate.agentur1.id,
          agentur2Id: agenturDuplicate.agentur2.id,
          trigger: trigger,
        });
      } else {
        this.$store.commit(SET_CONFIRMATION_MODAL_PAIR_AND_TRIGGER, {
          agentur1: agenturDuplicate.agentur1,
          agentur2: agenturDuplicate.agentur2,
          trigger: trigger,
        });
      }
    },
    onPageChange(ctx) {
      this.$store.dispatch(AGENTUR_DUPLICATES_PAGE_CHANGED, { pageContext: ctx });
      this.currentPage = ctx.page;
      window.scrollTo(0, 0);
    },
    onSortChanged(ctx) {
      this.$store.dispatch(AGENTUR_DUPLICATES_SORT_CHANGED, { sortContext: ctx });
      window.scrollTo(0, 0);
    },
  },
};
</script>
