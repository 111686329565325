<template>
  <div class="d-flex">
    <b-table-simple class="table table-sm">
      <b-thead>
        <b-tr>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td class="text-muted">{{ $t('AGENTUREN.AGENTURNUMMER') }}:</b-td>
          <b-td>{{ agentur1.id || '-' }}</b-td>
          <b-td>{{ agentur2.id || '-' }}</b-td>
        </b-tr>
        <b-tr :class="[agentur1.nameMatch ? 'bg-warning-o-30' : '']">
          <b-td class="text-muted">{{ $t('REISEN.COMMON.NAME') }}: </b-td>
          <b-td>{{ agentur1.name || '-' }}</b-td>
          <b-td>{{ agentur2.name || '-' }}</b-td>
        </b-tr>
        <b-tr :class="[agentur1.emailMatch ? 'bg-warning-o-30' : '']">
          <b-td class="text-muted">{{ $t('AUTH.INPUT.EMAIL') }}: </b-td>
          <b-td>{{ agentur1.email || '-' }}</b-td>
          <b-td>{{ agentur2.email || '-' }}</b-td>
        </b-tr>
        <b-tr :class="[agentur1.strasseMatch ? 'bg-warning-o-30' : '']">
          <b-td class="text-muted">{{ $t('COMMON.STRASSE') }}: </b-td>
          <b-td>{{ agentur1.strasse || '-' }}</b-td>
          <b-td>{{ agentur2.strasse || '-' }}</b-td>
        </b-tr>
        <b-tr :class="[agentur1.ortMatch ? 'bg-warning-o-30' : '']">
          <b-td class="text-muted">{{ $t('COMMON.ORT') }}: </b-td>
          <b-td>{{ agentur1.ort || '-' }}</b-td>
          <b-td>{{ agentur2.ort || '-' }}</b-td>
        </b-tr>
        <b-tr :class="[agentur1.plzMatch ? 'bg-warning-o-30' : '']">
          <b-td class="text-muted">{{ $t('COMMON.PLZ') }}: </b-td>
          <b-td>{{ agentur1.plz || '-' }}</b-td>
          <b-td>{{ agentur2.plz || '-' }}</b-td>
        </b-tr>
        <b-tr :class="[agentur1.telefonMatch ? 'bg-warning-o-30' : '']">
          <b-td class="text-muted">{{ $t('COMMON.TELEFON') }}: </b-td>
          <b-td>{{ agentur1.telefon || '-' }}</b-td>
          <b-td>{{ agentur2.telefon || '-' }}</b-td>
        </b-tr>
        <b-tr :class="[agentur1.steuernummerMatch ? 'bg-warning-o-30' : '']">
          <b-td class="text-muted">{{ $t('COMMON.STEUERNUMMER') }}: </b-td>
          <b-td>{{ agentur1.steuernummer || '-' }}</b-td>
          <b-td>{{ agentur2.steuernummer || '-' }}</b-td>
        </b-tr>
        <b-tr>
          <b-td class="text-muted">{{ $t('COMMON.WARNUNG') }}: </b-td>
          <b-td>{{ agentur1.warnung || '-' }}</b-td>
          <b-td>{{ agentur2.warnung || '-' }}</b-td>
        </b-tr>
        <b-tr>
          <b-td class="text-muted">{{ $t('AGENTUREN.BUCHUNGSSPERRE') }}: </b-td>
          <b-td>{{ getBuchungssperre(agentur1.buchungssperre) }}</b-td>
          <b-td>{{ getBuchungssperre(agentur2.buchungssperre) }}</b-td>
        </b-tr>
        <b-tr>
          <b-td class="text-muted">{{ $t('AGENTUREN.GESPERRTAB') }}: </b-td>
          <b-td>{{ getDate(agentur1.gesperrtAb) }}</b-td>
          <b-td>{{ getDate(agentur2.gesperrtAb) }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
export default {
  props: {
    agentur1: { type: Object, required: true },
    agentur2: { type: Object, required: true },
  },
  methods: {
    getBuchungssperre(buchungssperre) {
      return buchungssperre === null ? '-' : buchungssperre ? this.$t('COMMON.JA') : this.$t('COMMON.NEIN');
    },
    getDate(date) {
      return date === null ? '-' : this.$options.filters.date(date);
    },
  },
};
</script>
